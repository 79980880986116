import * as React from "react"
import { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Redirect } from "@reach/router"
import styled from "styled-components"
import ReactPlayer from "react-player/youtube"
import { Helmet } from "react-helmet"
import { Container } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "react-scroll"

import useSceenSize from "../hooks/useScreenSize"

import Header2 from "../components/header2"
import Footer from "../components/footer"

import LackofFacilities from "../images/landing_img/lack_of_facility.png"
import MixedMaterial from "../images/landing_img/mixed_material.png"
import MunicipalCollector from "../images/landing_img/municipal_collector.png"
import NsLandfill from "../images/landing_img/ns_landfill.png"
import RTrashBin from "../images/landing_img/r_trash_bin.png"
import RecyclePoint from "../images/landing_img/recycle_point.png"
import SLandfill from "../images/landing_img/s_landfill.png"
import TooDirty from "../images/landing_img/too_dirty.png"
import TransferStation from "../images/landing_img/transfer_station.png"
import URTrashbin from "../images/landing_img/u_r_trash_bin.png"
import UnclearLabel from "../images/landing_img/unclear_label.png"

const MainContainer = styled(Container)`
  padding: 0 !important;
  color: #000000;
  font-family: "Poppins", sans-serif;
`
const Section1Div = styled.div`
  height: 100vh;
  background: linear-gradient(180deg, #ffffff 60%, #f8f8f8 40%);
  padding-top: ${props =>
    props.header2Height ? `${props.header2Height}px` : `0px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SectionTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  text-align: center;
  width: 50%;
  @media (max-width: 991px) {
    width: 80%;
    margin-top: 0;
  }
`
const Section1Text1Style = styled.div`
  font-size: min(6vh, 10vw);
  line-height: min(8vh, 13vw);
  font-weight: bold;
  margin-top: 0;
  @media (max-width: 991px) {
    margin-top: 5vh;
  }
`
const Section1Text2Style = styled.div`
  padding-top: 2vh;
  font-size: min(3vh, 6vw);
`
const Section1PlayerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
`
const LearnMoreButton = styled.button`
  background-color: #84b250;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px;
  padding: 10px 25px;
  margin-top: 3vh;
  border: 3px solid #84b250;
  text-transform: uppercase;
  font-size: min(3vh, 6vw);
  margin-top: 3vh;
`
const Section2Div = styled.div`
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 991px) {
    height: unset;
  }
`
const Section2Container = styled.div`
  width: 80%;
  padding-top: unset;
  padding-bottom: unset;
  @media (max-width: 991px) {
    width: 90%;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
`
const Section2Text1Style = styled.div`
  font-size: min(6vh, 10vw);
  line-height: min(8vh, 13vw);
  font-weight: bold;
  text-transform: uppercase;
`
const Section2Text1StyleNum1 = styled.div`
  font-size: min(6vh, 10vw);
  line-height: min(8vh, 13vw);
  font-weight: bold;
  @media (max-width: 991px) {
    width: 90%;
  }
`
const Section2Text1StyleNum2 = styled.div`
  font-size: 4vh;
  line-height: 8vh;
  font-weight: bold;
  text-transform: uppercase;
`
const Section3Div = styled.div`
  height: 100vh;
  background-color: #84b250;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 991px) {
    height: unset;
  }
`
const Section3DivNum1 = styled.div`
  background-color: #84b250;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const Section3DivNum1Width80 = styled.div`
  width: 80%;
`
const Section3Text1Style = styled.div`
  font-size: min(6vh, 10vw);
  line-height: min(8vh, 13vw);
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
`
const Section3Text2Style = styled.div`
  padding-top: 2vh;
  font-size: 2vh;
  color: #ffffff;
`
// const Section3Block = styled.div`
//     background-color: #FFFFFF;
//     color: #000000;
//     border-radius: 15px;
//     border: 1px solid #FFFFFF;
//     width: 25vh;
//     display: flex;
//     flex-direction: column;
//     margin: 2.5vh;
//     padding: 1.5vh;
//     align-items: center;
//     box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
// `
const Section3BlockNum1 = styled(Link)`
  background-color: #ffffff;
  color: #000000;
  border-radius: 15px;
  border: 1px solid #ffffff;
  width: 35vh;
  display: flex;
  flex-direction: column;
  margin: 3vh;
  padding: 3vh;
  align-items: center;
  box-shadow: 0 10px 10px rgb(0 0 0 / 0.2);
  text-decoration: none;
  cursor: pointer;
  height: unset;
  @media (max-width: 991px) {
    margin: 1vw;
    padding: 1vw;
    width: 40vw;
    height: 100%;
  }
`
const Section3BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3vh;
`
const Section3BlockText = styled.div`
  font-size: 2vh;
  color: #000000;
  font-weight: bold;
  width: 90%;
  text-transform: uppercase;
`
//Section4
const Section5Div = styled.div`
  background-color: #f8f8f8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: unset;
  padding-bottom: unset;
  @media (max-width: 991px) {
    height: unset;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
`
const Section5BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: unset;
  margin-top: 3vh;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`
const Section5Block1 = styled.div`
  border: 3px solid #84b250;
  border-radius: 15px;
  width: 40vh;
  display: flex;
  flex-direction: column;
  margin: 3vh;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: 991px) {
    width: 90vw;
  }
`
const Section5TextStyle = styled.div`
  font-size: 2vh;
  line-height: 3vh;
  color: #ffffff;
  width: 100%;
  background-color: #84b250;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1vh;
`
const Section5TextStyle2 = styled.div`
  font-size: 2vh;
  line-height: 2vh;
  font-weight: normal;
`
const Section5TextStyle3 = styled.div`
  margin-top: 1vh;
  font-size: 2vh;
  line-height: 2.5vh;
  font-weight: normal;
  text-align: left;
  width: 80%;
  text-transform: unset;
`
const Section5TextStyle4 = styled.div`
  font-size: 2vh;
  line-height: 3vh;
  color: #ffffff;
  width: 100%;
  background-color: #4e4e4e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 1vh;
`
const Section5Block2 = styled.div`
  border: 3px solid #4e4e4e;
  border-radius: 15px;
  width: 40vh;
  display: flex;
  flex-direction: column;
  margin: 3vh;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: 991px) {
    width: 90vw;
  }
`
const Section7Div = styled.div`
  background-color: #f8f8f8;
  height: ${props => (props.width > props.height ? `100vh` : `unset`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: ${props => (props.width > props.height ? `unset` : `15vh`)};
  padding-bottom: ${props => (props.width > props.height ? `unset` : `15vh`)};
  @media (max-width: 991px) {
    height: unset;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
`
const Section7BlockContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.width > props.height ? `row` : `column`)};
  margin-top: 3vh;
  align-items: unset;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`
const Section7Block = styled.div`
  border: 3px solid #84b250;
  border-radius: 15px;
  width: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1vh;
  padding: 5vh 2vh;
  background-color: #ffffff;
`
const Section7TextStyle = styled.div`
  font-size: 2vh;
  line-height: 2.5vh;
`
const Section7TextStyle2 = styled.div`
  font-size: 2vh;
  line-height: 2.5vh;
`
const Section7TextStyle3 = styled.div`
  font-size: 3vh;
  line-height: 3.5vh;
  margin-top: 5vh;
`
const Section7Block2 = styled.div`
  background-color: #ffffff;
  color: #000000;
  border-radius: 15px;
  border: 1px solid #ffffff;
  width: 25vh;
  display: flex;
  flex-direction: column;
  margin: 3vh;
  padding: 3vh;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 0.2);
  text-align: center;
  padding-bottom: 1vh;
  @media (max-width: 991px) {
    width: 80%;
  }
`
const Section7TextStyle4 = styled.div`
  font-size: 2vh;
  line-height: 2.5vh;
  font-weight: bold;
  padding: 15px 0px;
`
const Section8TextStyle = styled.div`
  font-size: min(3vh, 6vw);
  line-height: 4vh;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
`
const Section8TextStyle2 = styled.div`
  font-size: min(6vh, 10vw);
  line-height: min(8vh, 13vw);
  font-weight: bold;
  color: #ffffff;
  margin-top: 2vh;
  text-transform: uppercase;
`
const Section8TextStyle2Num1 = styled.div`
  font-size: 5vw;
  font-weight: bold;
  color: #ffffff;
  margin-top: 2vh;
  text-transform: uppercase;
`

const Section4Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  padding-top: ${props =>
    props.header2Height ? `${props.header2Height + 25}px` : `5vh`};
  padding-bottom: ${props =>
    props.header2Height ? `${props.header2Height + 25}px` : `5vh`};
`
const Section4Text1Style = styled.div`
  font-size: min(3vh, 6vw);
  line-height: 4vh;
  font-weight: bold;
  text-transform: uppercase;
`
const MarginDiv = styled.div`
  margin-top: 5vh;
`
const Section4BlockContainer = styled.div`
  position: relative;
  width: max(40vw, 70vh);
  margin-top: 1vh;
  height: unset;
  @media (max-width: 991px) {
    width: unset;
    height: 50vh;
  }
`
const Section4Block1 = styled.div`
  background-color: #c4c4c4;
  border-radius: 15px;
  width: 60%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    width: unset;
  }
`
const Section4Block1Green = styled.div`
  background-color: #84b250;
  border-radius: 15px;
  width: 60%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    width: unset;
  }
`
const Section4Block2 = styled.div`
  border-radius: 15px;
  height: 25vh;
  z-index: 1;
  position: absolute;
  width: 50%;
  top: 0;
  left: unset;
  right: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0 0 20px rgb(0 0 0 / 0.3);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1vh;
  @media (max-width: 991px) {
    width: 80%;
    top: unset;
    left: 0;
  }
`
const Section4Img = styled.img`
  width: 50%;
`
const Dot = styled.div`
  height: 1.5vh;
  width: 1.5vh;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  margin-top: 1vh;
`
const Dot2 = styled.div`
  height: 1.5vh;
  width: 1.5vh;
  background-color: #84b250;
  border-radius: 50%;
  display: inline-block;
  margin-top: 1vh;
`
const Section4BlockContainerNum1 = styled.div`
  position: relative;
  width: max(40vw, 70vh);
  display: flex;
  justify-content: right;
  margin-top: 1vh;
  height: unset;
  @media (max-width: 991px) {
    width: unset;
    height: 50vh;
  }
`
const Section4Block1Num1 = styled.div`
  background-color: #c4c4c4;
  border-radius: 15px;
  width: 60%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    width: unset;
  }
`
const Section4Block1Num1Green = styled.div`
  background-color: #84b250;
  border-radius: 15px;
  width: 60%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    width: unset;
  }
`
const Section4Block2Num1 = styled.div`
  border-radius: 15px;
  height: 25vh;
  z-index: 1;
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  right: unset;
  bottom: 0;
  margin: auto;
  box-shadow: 0 0 20px rgb(0 0 0 / 0.3);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 991px) {
    width: 80%;
    top: unset;
    right: 0;
  }
`
const Section4Text1Style2 = styled.div`
  font-size: min(3vh, 6vw);
  line-height: 4vh;
  font-weight: bold;
  color: #84b250;
  text-transform: uppercase;
`
const PaddingInBlock = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const BlockForHover = styled.div`
  background-color: none;
  margin-left: 1vh;
  margin-right: 1vh;
  margin-top: 0vh;
  margin-bottom: 0vh;
  border: 1vh solid #84b250;
  @media (max-width: 991px) {
    margin-left: 0vh;
    margin-right: 0vh;
    margin-top: 0vh;
    margin-bottom: 0vh;
    padding-top: 0vw;
    padding-bottom: 2vw;
  }
  &:hover {
    border: 1vh solid #ffff00;
    border-radius: 15px;
  }
`
const Img1 = styled.img`
  width: 20vh;
`
const Img2 = styled.img`
  width: 35vh;
`
const Img3 = styled.img`
  width: 30vh;
  @media (max-width: 991px) {
    width: 35vw;
  }
`
const StartButton = styled.a`
  text-decoration: none;
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
`

const Section3 = () => {
  const intl = useIntl()

  return (
    <Section3Div>
      <Section3Text1Style>
        {intl.formatMessage({ id: "landing.section3.p1" })}
      </Section3Text1Style>
      <Section3Text2Style>
        {intl.formatMessage({ id: "landing.section3.p2" })}
      </Section3Text2Style>
      <Section3BlockContainer>
        <BlockForHover>
          <Section3BlockNum1 to="Section1" spy={true} smooth={false}>
            <Img3 src={URTrashbin} alt="image1" />
            <Section3BlockText>
              {intl.formatMessage({ id: "landing.section3.p3" })}
            </Section3BlockText>
          </Section3BlockNum1>
        </BlockForHover>
        <BlockForHover>
          <Section3BlockNum1 to="Section2" spy={true} smooth={false}>
            <Img3 src={RTrashBin} alt="image2" />
            <Section3BlockText>
              {intl.formatMessage({ id: "landing.section3.p4" })}
            </Section3BlockText>
          </Section3BlockNum1>
        </BlockForHover>
      </Section3BlockContainer>
    </Section3Div>
  )
}

const Index = () => {
  const intl = useIntl()
  const { width, height } = useSceenSize()
  const [header2Height, setHeader2Height] = useState(0)

  // useEffect(() => {
  //     navigate("/htmlpage/index.html");
  //   }, []);

  return (
    <MainContainer fluid>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trash Encyclopedia</title>
      </Helmet>
      <Header2 setHeaderHeight={setHeader2Height} />
      <Section1Div header2Height={header2Height}>
        <SectionTextDiv>
          <Section1Text1Style>
            {intl.formatMessage({ id: "landing.section1.p1" })}
          </Section1Text1Style>
          <Section1Text2Style>
            {intl.formatMessage({ id: "landing.section1.p2" })}
          </Section1Text2Style>
        </SectionTextDiv>
        <Section1PlayerDiv>
          {width > 991 ? (
            <ReactPlayer
              url="https://www.youtube.com/watch?v=HcHMM0e3xmE"
              controls="true"
              width="70vh"
              height="40vh"
            />
          ) : (
            <ReactPlayer
              url="https://www.youtube.com/watch?v=HcHMM0e3xmE"
              controls="true"
              width="90vw"
              height="55vw"
            />
          )}
          <LearnMoreButton>
            <StartButton href={"/" + intl.locale + "/mainpage"}>
              {intl.formatMessage({ id: "landing.section1.p3" })}
            </StartButton>
          </LearnMoreButton>
        </Section1PlayerDiv>
      </Section1Div>
      <Section2Div>
        <Section2Container>
          <Section2Text1Style>
            {intl.formatMessage({ id: "landing.section2.p1" })}
          </Section2Text1Style>
          <Section1Text2Style>
            {intl.formatMessage({ id: "landing.section2.p2" })}
            <br />
            {intl.formatMessage({ id: "landing.section2.p3" })}
          </Section1Text2Style>
          <Section1Text2Style>
            {intl.formatMessage({ id: "landing.section2.p4" })}
          </Section1Text2Style>
        </Section2Container>
      </Section2Div>
      <Section3 />
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Section4Div id="Section1" header2Height={header2Height}>
        <Section4Text1Style>
          {intl.formatMessage({ id: "landing.section4.p1" })}
        </Section4Text1Style>
        <Section2Text1Style>
          {intl.formatMessage({ id: "landing.section4.p2" })}
        </Section2Text1Style>
        <MarginDiv />
        <Section4BlockContainer>
          <Section4Block1>
            <Section4Img src={URTrashbin} alt="image3" />
          </Section4Block1>
          <Section4Block2>
            <Section4Text1Style>
              {intl.formatMessage({ id: "landing.section4.p3" })}
            </Section4Text1Style>
          </Section4Block2>
        </Section4BlockContainer>
        <Dot />
        <Dot />
        <Dot />
        <Section4BlockContainerNum1>
          <Section4Block1Num1>
            <Section4Img src={TransferStation} alt="image4" />
          </Section4Block1Num1>
          <Section4Block2Num1>
            <Section4Text1Style>
              {intl.formatMessage({ id: "landing.section4.p4" })}
            </Section4Text1Style>
          </Section4Block2Num1>
        </Section4BlockContainerNum1>
        <Dot />
        <Dot />
        <Dot />
        <Section4BlockContainer>
          <Section4Block1>
            <Section4Img src={SLandfill} alt="image5" />
          </Section4Block1>
          <Section4Block2>
            <Section4Text1Style>
              {intl.formatMessage({ id: "landing.section4.p5" })}
            </Section4Text1Style>
          </Section4Block2>
        </Section4BlockContainer>
        <LearnMoreButton>
          <StartButton href={"/" + intl.locale + "/mainpage"}>
            {intl.formatMessage({ id: "landing.section4.p6" })}
          </StartButton>
        </LearnMoreButton>
      </Section4Div>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Section5Div>
        <Section2Text1StyleNum1>
          {intl.formatMessage({ id: "landing.section5.p1" })}
          <Section5BlockContainer>
            <Section5Block1>
              <Section5TextStyle>
                {intl.formatMessage({ id: "landing.section5.p2" })}
              </Section5TextStyle>
              <Img2 src={SLandfill} alt="image6" />
              <PaddingInBlock>
                <Section5TextStyle2>
                  <b>{intl.formatMessage({ id: "landing.section5.p3" })}</b>{" "}
                  {intl.formatMessage({ id: "landing.section5.p4" })}
                </Section5TextStyle2>
                <Section5TextStyle3>
                  <ul>
                    <li>{intl.formatMessage({ id: "landing.section5.p5" })}</li>
                    <li>{intl.formatMessage({ id: "landing.section5.p6" })}</li>
                  </ul>
                </Section5TextStyle3>
              </PaddingInBlock>
            </Section5Block1>
            <Section5Block2>
              <Section5TextStyle4>
                {intl.formatMessage({ id: "landing.section5.p7" })}
              </Section5TextStyle4>
              <Img2 src={NsLandfill} alt="image7" />
              <PaddingInBlock>
                <Section5TextStyle2>
                  <b>{intl.formatMessage({ id: "landing.section5.p8" })}</b>{" "}
                  {intl.formatMessage({ id: "landing.section5.p9" })}
                </Section5TextStyle2>
                <Section5TextStyle3>
                  <ul>
                    <li>
                      {intl.formatMessage({ id: "landing.section5.p10" })}
                    </li>
                    <li>
                      {intl.formatMessage({ id: "landing.section5.p11" })}
                    </li>
                  </ul>
                </Section5TextStyle3>
              </PaddingInBlock>
            </Section5Block2>
          </Section5BlockContainer>
        </Section2Text1StyleNum1>
      </Section5Div>
      <Section3 />
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Section4Div id="Section2" header2Height={header2Height}>
        <Section4Text1Style2>
          {intl.formatMessage({ id: "landing.section6.p1" })}
        </Section4Text1Style2>
        <Section2Text1Style>
          {intl.formatMessage({ id: "landing.section6.p2" })}
        </Section2Text1Style>
        <MarginDiv />
        <Section4BlockContainer>
          <Section4Block1Green>
            <Section4Img src={RTrashBin} alt="image10" />
          </Section4Block1Green>
          <Section4Block2>
            {width > 991 ? (
              <Section4Text1Style>
                {intl.formatMessage({ id: "landing.section6.p3" })}
                <br />
                {intl.formatMessage({ id: "landing.section6.p4" })}
              </Section4Text1Style>
            ) : (
              <Section4Text1Style>
                {intl.formatMessage({ id: "landing.section6.p5" })}
              </Section4Text1Style>
            )}
          </Section4Block2>
        </Section4BlockContainer>
        <Dot2 />
        <Dot2 />
        <Dot2 />
        <Section4BlockContainerNum1>
          <Section4Block1Num1Green>
            <Section4Img src={MunicipalCollector} alt="image11" />
          </Section4Block1Num1Green>
          <Section4Block2Num1>
            <Section4Text1Style>
              {intl.formatMessage({ id: "landing.section6.p6" })}
            </Section4Text1Style>
          </Section4Block2Num1>
        </Section4BlockContainerNum1>
        <Dot2 />
        <Dot2 />
        <Dot2 />
        <Section4BlockContainer>
          <Section4Block1Green>
            <Section4Img src={RecyclePoint} alt="image12" />
          </Section4Block1Green>
          <Section4Block2>
            <Section4Text1Style>
              {intl.formatMessage({ id: "landing.section6.p7" })}
            </Section4Text1Style>
          </Section4Block2>
        </Section4BlockContainer>
        <LearnMoreButton>
          <StartButton href={"/" + intl.locale + "/mainpage"}>
            {intl.formatMessage({ id: "landing.section6.p8" })}
          </StartButton>
        </LearnMoreButton>
      </Section4Div>
      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Section7Div height={height} width={width}>
        <Section2Text1Style>
          {intl.formatMessage({ id: "landing.section7.p1" })}
        </Section2Text1Style>
        <Section7BlockContainer height={height} width={width}>
          <Section7Block>
            <Section7TextStyle>
              <b>{intl.formatMessage({ id: "landing.section7.p2" })}</b>
            </Section7TextStyle>
            <Section2Text1StyleNum2>
              {intl.formatMessage({ id: "landing.section7.p3" })}
            </Section2Text1StyleNum2>
            <Section7TextStyle2>
              {intl.formatMessage({ id: "landing.section7.p4" })}
            </Section7TextStyle2>
          </Section7Block>
          <Section7Block>
            <Section7TextStyle>
              <b>{intl.formatMessage({ id: "landing.section7.p5" })}</b>
            </Section7TextStyle>
            <Section2Text1StyleNum2>
              {intl.formatMessage({ id: "landing.section7.p6" })}
            </Section2Text1StyleNum2>
            <Section7TextStyle2>
              {intl.formatMessage({ id: "landing.section7.p7" })}
            </Section7TextStyle2>
          </Section7Block>
          <Section7Block>
            <Section7TextStyle>
              <b>{intl.formatMessage({ id: "landing.section7.p8" })}</b>
            </Section7TextStyle>
            <Section2Text1StyleNum2>
              {intl.formatMessage({ id: "landing.section7.p9" })}
            </Section2Text1StyleNum2>
            <Section7TextStyle2>
              {intl.formatMessage({ id: "landing.section7.p10" })}
            </Section7TextStyle2>
          </Section7Block>
        </Section7BlockContainer>
        <Section7TextStyle3>
          <b>{intl.formatMessage({ id: "landing.section7.p11" })}</b>
        </Section7TextStyle3>
        <Section7BlockContainer height={height} width={width}>
          <Section7Block2>
            <Img1 src={MixedMaterial} alt="image13" />
            <Section7TextStyle4>
              {intl.formatMessage({ id: "landing.section7.p12" })}
            </Section7TextStyle4>
          </Section7Block2>
          <Section7Block2>
            <Img1 src={LackofFacilities} alt="image14" />
            <Section7TextStyle4>
              {intl.formatMessage({ id: "landing.section7.p13" })}
            </Section7TextStyle4>
          </Section7Block2>
          <Section7Block2>
            <Img1 src={UnclearLabel} alt="image15" />
            <Section7TextStyle4>
              {intl.formatMessage({ id: "landing.section7.p14" })}
              <br />
              {intl.formatMessage({ id: "landing.section7.p15" })}
            </Section7TextStyle4>
          </Section7Block2>
          <Section7Block2>
            <Img1 src={TooDirty} alt="image16" />
            <Section7TextStyle4>
              {intl.formatMessage({ id: "landing.section7.p16" })}
            </Section7TextStyle4>
          </Section7Block2>
        </Section7BlockContainer>
      </Section7Div>
      <Section3DivNum1>
        <Section3DivNum1Width80>
          <Section8TextStyle>
            {intl.formatMessage({ id: "landing.section8.p1" })}
          </Section8TextStyle>
          {width > 991 ? (
            <Section8TextStyle2>
              {intl.formatMessage({ id: "landing.section8.p2" })}
              <br />
              {intl.formatMessage({ id: "landing.section8.p3" })}
            </Section8TextStyle2>
          ) : (
            <div>
              <Section8TextStyle2>
                {intl.formatMessage({ id: "landing.section8.p2" })}
              </Section8TextStyle2>
              <Section8TextStyle2Num1>
                {intl.formatMessage({ id: "landing.section8.p3" })}
              </Section8TextStyle2Num1>
            </div>
          )}
        </Section3DivNum1Width80>
      </Section3DivNum1>
      <Footer />
    </MainContainer>
  )
}

export default Index
