import * as React from "react"
import { useRef, useEffect } from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.css"
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"

import Logo from "../images/trash_encyclopedia_logo.png"
import Demo from "../../src/images/demo.png"
import useScreenSize from "../hooks/useScreenSize"

import HeaderIcon from "../images/header_icon.svg"

import { useIntl } from "gatsby-plugin-intl"

const HeaderContainer = styled(Container)`
  position: none;
  z-index: 1;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 1440px;
  @media (max-width: 991px) {
    width: initial;
  }
`
const NavLink = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  margin-bottom: 0;
  margin-right: 25px;
  &:hover {
    color: #84b250;
  }
  @media (max-width: 1199px) {
    font-size: 15px;
  }
  @media (max-width: 991px) {
    margin-right: unset;
    font-size: 18px;
  }
`
const NavLinkDemo = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  margin-bottom: 0;
  margin-right: 25px;
  background-image: url(${Demo});
  background-repeat: no-repeat;
  background-position: 100% 20%;
  background-size: 30%;
  padding-top: 10px;
  margin-top: -10px;
  @media (max-width: 1199px) {
    font-size: 15px;
  }
  @media (max-width: 991px) {
    margin-right: unset;
    font-size: 20px;
  }
`
const NavLinkLang = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  line-height: 30px;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 0;
  @media (max-width: 1199px) {
    font-size: 15px;
  }
  @media (max-width: 991px) {
    margin-right: unset;
    font-size: 18px;
  }
`
const NavbarCustomize = styled(Navbar)`
  background-color: #ffffff;
  .navbar-toggler-icon {
    background-image: url(${HeaderIcon}) !important;
  }
  .navbar-toggler {
    border-color: transparent !important;
    color: #84b250;
  }
`
const MainLogo = styled.img`
  width: 30vh;
  height: auto;
  @media (max-width: 768px) {
    width: 170px;
  }
`
const NavDropdownStyle = styled(NavDropdown)`
  text-align: center;
  .dropdown-menu {
    border: none;
    border-radius: 5px;
    min-width: 112px;
    box-shadow: 0px 4px 20px 0px #00000040;
  }
  .dropdown-item:hover {
    color: #84b250;
    background-color: white;
    font-weight: bold;
  }
  @media (min-width: 992px) .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .dropdown-menu[data-bs-popper] {
    left: 50%;
    transform: translateX(-50%);
  }
  .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0);
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dropdown-menu {
      box-shadow: none !important;
    }
    .dropdown-menu[data-bs-popper] {
      left: 0;
      transform: none;
    }
  }
  .dropdown-item {
    text-align: center;
  }
`
const NavLinkStyle = styled(Nav.Link)`
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
`
const NavbarBrandStyle = styled(Navbar.Brand)`
  @media (max-width: 991px) {
    position: absolute;
    left: calc(50% - 70px);
    top: 5px;
  }
`
const NotificationDiv = styled.div`
  display: block;
  background-color: #4e4e4e;
  font-family: "Poppins", sans-serif;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 11px;
  }
`

const MainContainer = styled.div`
  position: ${props => (props.state ? "none" : "fixed")};
  width: 100%;
  z-index: 2;
`

const Header2 = props => {
  const { width } = useScreenSize()
  const intl = useIntl()

  const ref = useRef(null)
  useEffect(() => {
    if (props.setHeaderHeight) {
      props.setHeaderHeight(ref.current.clientHeight)
    }
  }, [])

  return (
    <MainContainer state={props.state} ref={ref}>
      <HeaderContainer fluid>
        {/*<NotificationDiv><b>{intl.formatMessage({ id: "upgrade_intl.upgrade1" })}&nbsp;</b>{intl.formatMessage({ id: "upgrade_intl.upgrade2" })}<b>&nbsp;{intl.formatMessage({ id: "upgrade_intl.upgrade3" })}</b></NotificationDiv>*/}
        <NavbarCustomize expand="lg">
          <Container>
            <NavbarBrandStyle href={"/home-" + intl.locale}>
              <MainLogo src={Logo} />
            </NavbarBrandStyle>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <NavLinkStyle
                  className="d-flex align-items-center"
                  href={"/" + intl.locale + "/101trash"}
                >
                  <NavLink>
                    {intl.formatMessage({ id: "header_intl.101trash" })}
                  </NavLink>
                </NavLinkStyle>
                <NavLinkStyle
                  className="d-flex align-items-center"
                  href={"/map-" + intl.locale}
                >
                  <NavLink>
                    {intl.formatMessage({ id: "header_intl.map" })}
                  </NavLink>
                </NavLinkStyle>
                <NavLinkStyle
                  className="d-flex align-items-center"
                  href={"/quiz"}
                >
                  <NavLink>
                    {intl.formatMessage({ id: "header_intl.wastequiz" })}
                  </NavLink>
                </NavLinkStyle>
                {/*<NavDropdownStyle
                className={width <= 991 ? "" : "d-flex align-items-center"}
                title={<NavLink>{intl.formatMessage({ id: "header_intl.zerowastelife" })}</NavLink>}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="#">{intl.formatMessage({ id: "header_intl.comingsoon" })}</NavDropdown.Item>
              </NavDropdownStyle>*/}
                <NavLinkStyle
                  className="d-flex align-items-center"
                  href={"/" + intl.locale + "/faq"}
                >
                  <NavLink>
                    {intl.formatMessage({ id: "header_intl.faq" })}
                  </NavLink>
                </NavLinkStyle>
                <NavLinkStyle
                  className="d-flex align-items-center"
                  href={"/about-" + intl.locale}
                >
                  <NavLink>
                    {intl.formatMessage({ id: "header_intl.aboutus" })}
                  </NavLink>
                </NavLinkStyle>
                <NavDropdownStyle
                  className={width <= 991 ? "" : "d-flex align-items-center"}
                  title={<NavLinkLang>{intl.locale}</NavLinkLang>}
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href={"/home-en"}>
                    {intl.formatMessage({ id: "language_intl.english" })}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={"/home-bm"}>
                    {intl.formatMessage({
                      id: "language_intl.bahasa_malaysia",
                    })}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={"/home-tm"}>
                    {intl.formatMessage({ id: "language_intl.tamil" })}
                  </NavDropdown.Item>
                  <NavDropdown.Item href={"/home-cn"}>
                    {intl.formatMessage({ id: "language_intl.mandarin" })}
                  </NavDropdown.Item>
                </NavDropdownStyle>
                <NavDropdown.Divider />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </NavbarCustomize>
      </HeaderContainer>
    </MainContainer>
  )
}
export default Header2
